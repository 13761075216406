import React from "react";

const SvgArtfixLogo = props => (
  <svg
    id="artfix-logo_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 450.85 204"
    {...props}
  >
    <defs>
      <style>{".artfix-logo_svg__cls-4{fill:#fff}"}</style>
    </defs>
    <path
      d="M225.44 403H124.57A51.56 51.56 0 0173 351.44V250.56A51.56 51.56 0 01124.57 199h100.87A51.57 51.57 0 01277 250.56v100.88A51.57 51.57 0 01225.44 403"
      transform="translate(-73.01 -199)"
      fill="#00adee"
    />
    <path
      d="M235.64 403H134.77a51.56 51.56 0 01-51.56-51.56V250.56A51.56 51.56 0 01134.77 199h100.87a51.57 51.57 0 0151.57 51.56v100.88A51.57 51.57 0 01235.64 403"
      transform="translate(-73.01 -199)"
      fill="#eb008b"
    />
    <path
      d="M245.84 403H145a51.57 51.57 0 01-51.6-51.56V250.56A51.57 51.57 0 01145 199h100.84a51.56 51.56 0 0151.56 51.56v100.88A51.56 51.56 0 01245.84 403"
      transform="translate(-73.01 -199)"
      fill="#fff100"
    />
    <path
      d="M256 403H155.16a51.57 51.57 0 01-51.56-51.56V250.56A51.57 51.57 0 01155.16 199H256a51.57 51.57 0 0151.56 51.56v100.88A51.57 51.57 0 01256 403"
      transform="translate(-73.01 -199)"
    />
    <path
      className="artfix-logo_svg__cls-4"
      d="M214.32 351.71a19.24 19.24 0 01-1-3.07c-.28-1.19-.53-2.47-.74-3.79s-.37-2.68-.46-4.09-.12-2.76-.12-4.11V299a28.26 28.26 0 00-2.15-11.37 22.43 22.43 0 00-6-8.17 25.61 25.61 0 00-9.22-4.89A40 40 0 00183 273a37.11 37.11 0 00-12.42 2 31.82 31.82 0 00-9.64 5.26 24.33 24.33 0 00-6.26 7.53 18.8 18.8 0 00-2.24 8.83v.77l10.16.08v-.78a11.84 11.84 0 011.45-5.7 15.3 15.3 0 014.1-4.74 20.12 20.12 0 016.35-3.25 26.35 26.35 0 018.09-1.19c6.33 0 11.18 1.54 14.44 4.57s4.89 7.24 4.89 12.54v8.05h-15.17a60.68 60.68 0 00-14.94 1.71 35.43 35.43 0 00-11.41 5 23 23 0 00-7.28 8 23.27 23.27 0 00-.67 19.67 21.8 21.8 0 005.25 7.26 23.92 23.92 0 008.15 4.78 31.33 31.33 0 0010.57 1.7 35.6 35.6 0 008.73-1 37.31 37.31 0 007.41-2.71 31.66 31.66 0 006.11-4 32 32 0 003.44-3.33c.1 1.36.25 2.71.43 4a22 22 0 001.15 5.07c.18.47.77 1.85.77 1.85h9.92v-3zm-12.4-36.62v16.15a23.87 23.87 0 01-3.62 5.66 25.25 25.25 0 01-5.43 4.7 27.06 27.06 0 01-7 3.17 29.42 29.42 0 01-8.43 1.15 20.32 20.32 0 01-7-1.13 15.92 15.92 0 01-5.24-3.08 13.57 13.57 0 01-3.3-4.55 13.87 13.87 0 01-1.15-5.61 13.13 13.13 0 011.79-6.82 15.41 15.41 0 015.24-5.16 28.22 28.22 0 018.56-3.31 53.1 53.1 0 0111.66-1.17zM260.4 274a11.78 11.78 0 00-2.68-.72 20.4 20.4 0 00-3.43-.3 24.4 24.4 0 00-14 3.91 26.27 26.27 0 00-7.55 8l-.12-10.41h-10.17V355h10.3v-54.83a30.73 30.73 0 012.86-6.93 21.16 21.16 0 014.44-5.43 19.54 19.54 0 016.09-3.55 22.41 22.41 0 017.79-1.27 31.52 31.52 0 015.79.5l.9.16.26-9.42zM308.44 345l-.85.13-3.23.47a19.93 19.93 0 01-7.3-.2 6.26 6.26 0 01-2.88-1.67 9 9 0 01-2-3.59 20.47 20.47 0 01-.79-6.34v-51.09h17.24v-8.6h-17.28v-19.78h-10.18v19.78h-14.34v8.6h14.33v51.13a32.91 32.91 0 001.3 10 17.14 17.14 0 003.66 6.6 13 13 0 005.7 3.55 23.65 23.65 0 007 1 40.91 40.91 0 005.17-.33 20.63 20.63 0 004.31-.94l.54-.2z"
      transform="translate(-73.01 -199)"
    />
    <path d="M306.58 114.62h-39.66v41.36h-20.66V55.33h65.28v16.73h-44.62v25.89h39.66v16.67zM324.83 55.33h20.66v100.65h-20.66z" />
    <path
      d="M488.78 302.71l-1.08-.71 2.14-3.07 1.08.75zm4.28-6.14l-1.08-.75 2.14-3.07 1.07.75zm-15.79-4.87l-.76-1.11 2-2.88 1.08.74zm20.06-1.26l-1.07-.75 2.14-3.07 1.07.75zm-15.66-5.08l-1.08-.74 2.11-3.09 1.08.74zm19.94-1.06l-1.07-.75 2.14-3.06 1.07.74zm-15.72-5.12l-1.08-.73 2.12-3.09 1.08.74zm20-1l-1.07-.75 2.14-3.07 1.07.75zM490.12 273l-1.08-.74 2.11-3.08 1.08.74zm20.05-1l-1.07-.75 2.13-3.06 1.08.74zm-15.82-5.18l-1.07-.74 2.12-3.08 1.08.75zm20.1-1l-1.08-.75 2.14-3.07 1.08.75zm-15.84-5.19l-1.07-.75 2.13-3.07 1.08.75zm20.12-.95l-1.08-.75 2.14-3.06 1.08.74zm2.64-4.28h-3.74v-1.31h3.74zm-7.48 0h-3.74v-1.31h3.74zm-7.48 0h-3.73v-1.31h3.73z"
      transform="translate(-73.01 -199)"
      fill="#171717"
    />
    <path
      d="M506.26 329.8q-4.58-6.58-9.08-13c-.87-1.25-1.7-2.45-2.55-3.66l-.61-.88c-1.84-2.66-3.65-5.26-5.35-7.77l-9.45-13.88-24.78-36.26h-24.31l34.94 50.14L430 355h22.25l24.89-36.49 25 36.49h21.66q-4-5.73-8.51-12.16l-9.09-13"
      transform="translate(-73.01 -199)"
    />
  </svg>
);

export default SvgArtfixLogo;
